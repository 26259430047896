import { Formik } from 'formik';
import ReactSelect from 'react-select';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Theme from '../../../styles/Theme';
import { InputFieldForm } from '../../atoms/Field/StyledField';
import Button from '../../atoms/Button/Button';
import Spinner from '../../atoms/Spinner/Spinner';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import Error, { Success } from '../../atoms/Error/StyledError';
import useFormTicketManager from '../../../hooks/useFormTicketManager';
import { FormTicket } from '../../organisms/Login/StyledForm';
import { FormSkeletonSelect } from '../../organisms/FilterForm/StyledForm';

export default function TicketManager() {
  const { planId } = useParams();

  const {
    navigate,
    internalValue,
    setInternalValue,
    options,
    handleSubmit,
    initialValuesTicket,
    validationSchemaTicket,
    hashTicket,
    nifRef,
    ticketTypeRef,
    planIdRef,
    subjectValue,
    bodyValue,
    setSubjectValue,
    setBodyValue,
    // setFile,
    handleInputChange,
    errorMessage,
    submitError,
    submitSuccess,
    stripHtml,
  } = useFormTicketManager();

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  if (planId && hashTicket.arrayPlan && !internalValue.planId) {
    const planSelected = hashTicket.arrayPlan
      ?.filter((e :any) => e.value.toString() === planId)[0];
    handleInputChange(planSelected, 'planId');
    setInternalValue({
      ...internalValue,
      planId: planSelected?.value,
    });
  }

  return (
    <Container>
      <ButtonBack onClick={() => navigate('/help')} type="button">Volver</ButtonBack>
      <Typography color={Theme.colorPrimary} size="32px" margin="0 0 1rem" display="block" align="center" weight="500">Creación de incidencia</Typography>
      <div style={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto' }}>
        <Formik
          enableReinitialize
          initialValues={initialValuesTicket}
          validationSchema={validationSchemaTicket}
          onSubmit={() => {}}
        >
          {({ isSubmitting }) => (
            <FormTicket>
              {hashTicket.arrayRelatedUserId ? (
                <div className="field">
                  <label htmlFor="state">Nif Asociado a la incidencia</label>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select-relatedUserId"
                    defaultValue={
                      options.arrayRelatedUserId
                    }
                    isSearchable
                    isClearable
                    name="category"
                    ref={nifRef}
                    options={hashTicket.arrayRelatedUserId}
                    onChange={(e: any) => handleInputChange(e, 'relatedUserId')}
                  />
                  {(internalValue?.relatedUserId === undefined
                  && errorMessage?.relatedUserId) && (
                    <Error>{errorMessage?.relatedUserId}</Error>
                  )}
                </div>
              ) : (
                <FormSkeletonSelect />
              )}
              {hashTicket.arrayTicketType ? (
                <div className="field">
                  <label htmlFor="state">Tipo de incidencia</label>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select-ticket-type"
                    defaultValue={options.arrayTicketType}
                    isSearchable
                    isClearable
                    name="ticketType"
                    ref={ticketTypeRef}
                    options={hashTicket.arrayTicketType}
                    onChange={(e: any) => handleInputChange(e, 'ticketType')}
                  />
                  {(internalValue?.ticketType === undefined
                  && errorMessage?.ticketType) && (
                    <Error>{errorMessage?.ticketType}</Error>
                  )}
                </div>
              ) : (
                <FormSkeletonSelect />
              )}
              {hashTicket.arrayPlan ? (
                <div className="field">
                  <label htmlFor="state">Plan</label>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select-plan-type"
                    defaultValue={hashTicket.arrayPlan
                      ?.filter((e :any) => e.value.toString() === planId)[0]}
                    isSearchable
                    isClearable
                    name="planId"
                    ref={planIdRef}
                    options={hashTicket.arrayPlan}
                    onChange={(e: any) => handleInputChange(e, 'planId')}
                  />
                  {((internalValue?.planId === undefined)
                  && errorMessage?.planId) && (
                    <Error>{errorMessage?.planId}</Error>
                  )}
                </div>
              ) : (
                <FormSkeletonSelect />
              )}
              <div className="field">
                <label htmlFor="state">Asunto</label>
                <InputFieldForm
                  name="subject"
                  type="text"
                  placeholder="Asunto"
                  autoComplete="off"
                  value={subjectValue}
                  onChange={(e: any) => {
                    setSubjectValue(e.target.value);
                    handleInputChange(e, 'subject');
                  }}
                  className={internalValue?.subject === '' || (internalValue?.subject && internalValue?.subject?.length > 144) ? 'error' : ''}
                />
                <span>{`${internalValue?.subject?.length || 0}/144`}</span>
                { errorMessage.subject?.length !== 0 && (
                  <Error>{errorMessage.subject}</Error>
                )}
              </div>
              <div className="field">
                <label htmlFor="state">Mensaje</label>
                <ReactQuill
                  value={bodyValue}
                  placeholder="Mensaje"
                  onChange={(e: any) => {
                    setBodyValue(e);
                    handleInputChange(e, 'body');
                  }}
                  modules={modules}
                  className={stripHtml(internalValue?.body) === '' || (stripHtml(internalValue?.body) && stripHtml(internalValue?.body).length > 2000) ? 'error' : ''}
                />
                <span>{`${stripHtml(internalValue?.body).length || 0}/2000`}</span>
                {errorMessage.body?.length !== 0 && (
                  <Error>{errorMessage.body}</Error>
                )}
              </div>
              {submitError !== '' && (
                <Error>{submitError}</Error>
              )}
              {submitSuccess !== '' && (
                <Success>{submitSuccess}</Success>
              )}
              <Button
                type="button"
                size={262}
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {!isSubmitting ? (
                  'Enviar'
                ) : (
                  <Spinner background="rgba(200,200,200,0.6)" color="#fff" />
                )}
              </Button>
            </FormTicket>
          )}
        </Formik>
      </div>
    </Container>
  );
}
