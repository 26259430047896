import { createGlobalStyle } from 'styled-components';
import Theme from './Theme';
import DMSansRegularWoff from '../assets/fonts/DMSans-Regular.woff';
import DMSansMediumWoff from '../assets/fonts/DMSans-Medium.woff';
import DMSansBoldWoff from '../assets/fonts/DMSans-Bold.woff';
import FixtureCondensedMediumWoff from '../assets/fonts/Fixture-Condensed-Medium.woff';
import DMSansRegularWoff2 from '../assets/fonts/DMSans-Regular.woff2';
import DMSansMediumWoff2 from '../assets/fonts/DMSans-Medium.woff2';
import DMSansBoldWoff2 from '../assets/fonts/DMSans-Bold.woff2';
import FixtureCondensedMediumWoff2 from '../assets/fonts/Fixture-Condensed-Medium.woff2';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'DM Sans';
    src: local('DM Sans'), local('DM Sans'),
    url(${DMSansRegularWoff2}) format('woff2'),
    url(${DMSansRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: local('DM Sans'), local('DM Sans'),
    url(${DMSansMediumWoff2}) format('woff2'),
    url(${DMSansMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: local('DM Sans'), local('DM Sans'),
    url(${DMSansBoldWoff2}) format('woff2'),
    url(${DMSansBoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Fixture Condensed Medium';
    src: local('Fixture Condensed Medium'), local('Fixture-Condensed-Medium'),
    url(${FixtureCondensedMediumWoff}) format('woff2'),
    url(${FixtureCondensedMediumWoff2}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  *{
    box-sizing: border-box;
    &:focus-visible {
      outline: none;
    }
  }

  html,
  body,
  #root {
    min-height: calc(var(--vh, 1vh) * 100);
  }

  body,
  #root {
    margin: 0;
    display: flex;
    flex-direction: column;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .btn {
    border-radius: ${Theme.radius};
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    line-height: 1.25;
    min-width: 100%;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:hover {
      box-shadow: ${Theme.shadow};
      transform: translateY(-2px);
    }

    &--primary {
      background-color: ${Theme.colorPrimary};
      color: ${Theme.colorWhite};

      span {
        color: ${Theme.colorWhite};
      }
    }
  }

  .link {
    text-decoration: underline;
  }

  .line {
    width: 100%;
    height: 1px;
    background: ${Theme.colorSecondary3};
    margin: 4rem auto;
  }

  .group-selector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0 1rem;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    margin: 2rem 0 4rem;

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .link {
      color: ${Theme.colorPrimary};
    }
  }

  .grid-item {
    width: 100%;
    background-color: ${Theme.colorSecondary2};
    padding: 1rem 2rem;

    tbody {
      tr {
        background-color: ${Theme.colorWhite};
        color: ${Theme.fontColor};
      }
      td {
        height: auto;
        &::before {
          background-color: ${Theme.colorWhite};
        }
      }
    }

    th {
      height: 37px;
      font-size: 14px;
      line-height: 1;

      &:first-child {
        &::before {
          content: '';
          font-size: 14px;
        }
      }
    }

    td {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    @media screen and (min-width: 768px) {
      tbody {
        td {
          height: 35px;

          &::before {
            height: 35px;
          }
        }
      }
    }
  }

  .table-wrap {
    overflow: auto;
  }

  .list-cta {
    margin: 0 0 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
  }

  .btn-outline,
  .btn-outline-secondary {
    background-color: ${Theme.colorWhite};
    border: 1px solid ${Theme.colorBorder};
    border-radius: 8px;
    cursor: pointer;
    color: #666666;
    font-weight: 400;
    padding: 0.72rem 1rem;
    text-transform: underline;

    &:hover {
      color: ${Theme.colorPrimary};
    }
  }

  .btn-outline-secondary {
    font-weight: 600;
    font-size: 14px;
    border: 2px solid ${Theme.colorSecondary3};

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }

    &:hover {
      color: #666666;
    }
  }

  .groupFields {
    > * {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  input[type="date"] {
    background-color: hsl(0, 0%, 100%);
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    display: block;
    padding: 10px;
    font-family: inherit;
  }

  .rangeDate {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > * {
      width: 100%;
    }

    input {
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .field.last-position {
    order: 1;
  }

  @media screen and (min-width: 768px) {
    .filter__grid {
      display: flex;
      gap: 1rem;
    }

    .filter__grid .filter__form_1 {
      width: 75%;
    }

    .filter__grid .filter__form_1 .groupFields > * {
      width: calc(33.333% - 0.75rem);
    }

    .filter__grid .filter__form_2 {
      width: 25%;
      position: relative;
    }

    .filter__grid .filter__form_2 .groupFields > * {
      width: 100%;
      margin-bottom: 0;
    }

    .filter__grid .filter__form_2 .groupCta {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20rem;
    }

    .filter__grid .filter__form_1 .skeleton > div,
    .filter__grid .filter__form_2 .skeleton > div {
      flex-grow: 1;
    }

    .filter__button {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2rem;
    }

    .selector {
      max-width: 238px;
    }

    .grid {
      display: flex;

      > * {
        width: calc(50% - 1rem);
      }
    }

    .groupFields {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      > * {
        margin-bottom: 0;
        width: calc(25% - 0.75rem);
      }

      @media screen and (min-width: 768px) {
        &.dateRange {
          > * {
            width: calc(20% - 0.75rem);

            &.rangeDate {
              width: calc(40% - 0.75rem);
            }
          }
        }
      }
    }

    .groupCta {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem;

      button[type="submit"] {
        margin-left: 1rem;
        padding: 0.62rem 1rem;
      }

      button {
        &:before {
          display: none;
        }
      }
    }
  }

  .search {
    appearance: none;
    background: ${Theme.colorWhite};
    border: 1px solid ${Theme.colorSecondary3};
    border-radius: ${Theme.radiusField};
    font-family: ${Theme.fontFamily};
    font-size: 16px;
    padding: 12px 16px;
    height: 48px;
    margin-bottom: 8px;
    transition: box-shadow 0.3s ease, outline 0.3s ease;
    width: 100%;
    margin-bottom: 2rem;

    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 20 21' fill='%239CB0BC' xmlns='http://www.w3.org/2000/svg'><path d='M19.3242 15.8198L16.02 12.5156C16.6208 11.3141 17.0213 9.91239 17.0213 8.51064C17.0213 3.80476 13.2165 0 8.51064 0C3.80476 0 0 3.80476 0 8.51064C0 13.2165 3.80476 17.0213 8.51064 17.0213C9.91239 17.0213 11.3141 16.6208 12.5156 16.02L15.8198 19.3242C16.821 20.3254 18.3229 20.3254 19.3242 19.3242C20.2253 18.3229 20.2253 16.821 19.3242 15.8198ZM8.51064 15.0188C4.90613 15.0188 2.0025 12.1151 2.0025 8.51064C2.0025 4.90613 4.90613 2.0025 8.51064 2.0025C12.1151 2.0025 15.0188 4.90613 15.0188 8.51064C15.0188 12.1151 12.1151 15.0188 8.51064 15.0188Z'></path></svg>");
    background-position: 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1rem;
    padding-left: 2rem;

    &::placeholder {
      color: ${Theme.colorSecondary3};
    }

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 0.25rem rgba(156, 176, 188, 0.32);
    }

    &.error {
      box-shadow: 0 0 0 0.18rem ${Theme.colorError};
    }
  }

  .summary__csv {
    > div {
      background-color: transparent;
    }
  }

  .tb__ranking-calendar {
    tbody {
      td {
        &:nth-child(1) {
          .align-center {
            text-align: left;
          }
        }

        &:nth-child(2) {
          .align-right {
            text-align: center;
            padding-right: 0;
          }
        }

        &:nth-child(3) {
          span:not(.tooltip) {
            display: flex;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .tb__help {
    tbody {
      td {
        &:nth-child(2) {
          width: 30%;
        }
      }
    }
  }

  .delegates__table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .delegates__table .table-container{
    width: 100%;
  }

  .delegates__table__graphics > div > div{
    display: none;
  }

  .statistic__list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    gap: 1.25rem;
    display: flex;
    flex-direction: column;
  }

  .statistic__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 37px;
  }

  .score__container {
    height: 185px;
    width: 100%;
  }

  .score__container.rappel > div {
    height: 184px;
    max-height: 184px;
  }

  .card__link {
    color: ${Theme.colorPrimary};
    -webkit-text-decoration: underline;
    text-decoration: underline;
    display: inline-block;
  }

  .card__button {
    background-color: #D81E05;
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 900;
    -webkit-text-decoration: none;
    text-decoration: none;
    padding: 0.7rem 0;
    display: inline-block;
    font-size: 14px;
    width: 100%;
    text-align: center;
    margin-top: 0.7rem;
  }

  @media screen and (min-width: 1024px) {
    .delegates__table {
      display: flex !important;
      flex-direction: row !important;
      gap: 4rem !important;

      > div:nth-child(1) {
        width: 35%;
      }

      > div:nth-child(2) {
        width: calc(65% - 2rem);
      }
    }
  }

  .banner__custom {
    position: relative;
    margin: 6rem auto 0;
    width: 90%;
    overflow: hidden;

    > div {
      display: block;
      position: relative;
      width: 100%;
      height: 14rem;
      overflow: hidden;
      box-shadow: 6px 4px 30px rgba(0,0,0,0.15);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        z-index: 2;
      }

      span {
        font-family: 'Fixture Condensed Medium',sans-serif;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 25px;
        line-height: 1;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        max-width: 89%;
        position: absolute;
        z-index: 3;
        background-color: #D81E05;
        padding: 0.82rem 1.5rem;
        border-radius: 8px;
        margin-top: 8px;
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      max-height: none;
      max-width: none;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%,-50%);
      object-fit: cover;
    }
    a {
      text-decoration: none;
      color: #FFFFFF;
      text-transform: none;
    }
  }

  @media screen and (min-width: 768px) {
    .banner__custom {
      margin: 7rem auto 3.5rem;
      > div {
        span {
          width: fit-content;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .banner__custom {
      width: 100%;
      >div {
        img {
          transform: translate(-50%,-50%);
        }
      }
    }
  }

  .banner__custom__home {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 2rem;

    > div {
      display: block;
      position: relative;
      width: 100%;
      height: 14rem;
      overflow: hidden;
      box-shadow: 6px 4px 30px rgba(0,0,0,0.15);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        z-index: 2;
      }
      span {
        font-family: 'Fixture Condensed Medium',sans-serif;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 20px;
        line-height: 1;
        text-decoration: none;
        transition: all 0.3s ease;
        position: absolute;
        z-index: 3;
        background-color: #D81E05;
        padding: 0.82rem 1.5rem;
        border-radius: 8px;
        margin-top: 80px;
      }
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      max-height: none;
      max-width: none;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%,-50%);
      object-fit: cover;
    }
    a {
      color: #FFFFFF;
      text-decoration: none;
    }
  }

  .banner__custom__home__2024 {
    > div {
      height: 18rem;
    }
  }

  .banner__notifications {
    position: relative;
    margin: 6rem auto 0;
    width: 90%;
    box-shadow: 6px 4px 30px rgba(0,0,0,0.15);
    padding-bottom: 5rem;
    background-color: ${Theme.colorTertiary};
    min-height: 250px;
    
    div {
      justify-content: flex-start;
    }

    &:nth-child(1) {
      > div {
        top: 2.5rem;
      }
    }
    > div {
      display: block;
      position: relative;
      width: calc(100% - 240px);
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: all 0.3s ease;
        z-index: 2;
      }

      span {
        background-color: #D81E05;
        border-radius: 8px;
        color: #FFFFFF;
        font-weight: 900;
        -webkit-text-decoration: none;
        padding: 0.8rem;
        display: inline-block;
        font-size: 14px;
        text-align: center;
        margin-top: 0.7rem;
        a {
          color: #FFFFFF !important;
          text-decoration: none;
        }
      }
    }

    img {
      position: absolute;
      top: 10px;
      right: 0;
      width: 200px;     
    }
    
    button {
      display: block;
      position: relative;
    }
  }

  .banner__text {
    font-family: 'Fixture Condensed Medium',sans-serif;
    font-size: 52px;
    z-index: 2;
    font-weight: 900;
    position: absolute;
    color: ${Theme.colorPrimary};
  }

  .banner__text__white {
    color: white;
  }

  .banner__text__container{
    display: flex;
    margin: 40px;
  }

  .data_campaign_global_position {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .graphic_campaign_global_position {
    display: flex;
    justify-content: center;
  }
`;

export default GlobalStyle;
