import { gql } from 'graphql-request';

const USER_TICKETS = gql`
  query userTickets($userId: ID!, $year: Int!, $startDate: String, $endDate: String, $planType: String, $ticketType: Int, $ticketStatus: Int) {
    userTickets(userId: $userId, year: $year, startDate: $startDate, endDate: $endDate, planType: $planType, ticketType: $ticketType, ticketStatus: $ticketStatus) {
      nodes{
        id
        body
        openedAt
        ticketStatus
        subject
        ticketType
        internalId
        planId
        planName
        userName
        relatedUserId
        createdAt
        updatedAt
      }
    }
  }
`;
export default USER_TICKETS;
