import { gql } from 'graphql-request';

const GET_TICKET_INFO = gql`
  query MyQuery($ticketId: ID!) {
    ticketInfo(ticketId: $ticketId) {
      id
      internalId
      subject
      body
      ticketStatus
      ticketType
      openedAt
      relatedUserName
      relatedUserNif

      planType
      planName

      ticketResponses {
        userName
        targetUserName
        userNif
        targetUserNif
        body
        actionType
        createdAt
      }
    }
  }
`;

export default GET_TICKET_INFO;
