export default class TicketModel {
  public id?: string;

  public body?: string;

  public ticketStatus?: number;

  public subject?: string;

  public ticketType?: number;

  public planId?: string;

  public relatedUserId?: number;

  public createdAt?: string;

  public updatedAt?: string;

  constructor(param?: TicketModel) {
    if (param) {
      this.id = param.id;
      this.body = param.body;
      this.ticketStatus = param.ticketStatus;
      this.subject = param.subject;
      this.ticketType = param.ticketType;
      this.planId = param.planId;
      this.relatedUserId = param.relatedUserId;
      this.createdAt = param.createdAt;
      this.updatedAt = param.updatedAt;
    }
  }
}
