import { gql } from 'graphql-request';

const RESPONSE_TICKET_GQL = gql`
  mutation responseTicket(
    $ticketId: ID!,
    $body: String!,
    $action: String!
  ) {
    responseTicket(
      ticketId: $ticketId,
      body: $body,
      action: $action
    ) {
      body
      createdAt
      id
      internalId
      openedAt
      planId
      relatedUserId
      subject
      ticketStatus
      ticketType
      updatedAt
    }
  }
`;

export default RESPONSE_TICKET_GQL;
